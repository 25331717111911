<template>
<v-container centered class="mt-12">
  <!-- <v-layout row v-if="authError">
    <v-flex xs12 sm6 offset-sm3>
      <alert-cmp @dismissed="onDismissed" :err="authError"></alert-cmp>
    </v-flex>
  </v-layout> -->
  <v-layout row>
    <v-flex xs12 sm6 offset-sm3>
      <v-card max-width="444">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
          <v-spacer />
          <v-btn @click="$store.dispatch('switchLocale')">{{ $t('common.otherlocale') }}</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <form @submit.prevent="onSignIn">
              <!-- <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="empid"
                    :label="$t('login.empid')"
                    id="empid"
                    v-model="empid"
                    type="number"
                    required></v-text-field>
                </v-flex>
              </v-layout> -->
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    name="email"
                    :label="$t('login.mail')"
                    id="user"
                    v-model="email"
                    type="email"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12 row class="mx-auto">
                  <v-text-field
                    name="password"
                    :label="$t('login.password')"
                    id="password"
                    v-model="password"
                    :type="inputType"
                    @blur="$store.dispatch('resize')"
                    required></v-text-field>
                  <v-icon class="ms-2" large @click="switchInput">{{ inputType === 'password' ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                </v-flex>

              </v-layout>

              <v-layout row>
                <v-flex xs12>
                  <v-btn type="submit" color="primary" :disabled="loading" :loading="loading">
                    {{ $t('login.signIn') }}
                    <span slot="loader" class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
export default {
  methods: {
    onSignIn () {
      this.$store.dispatch('signUserIn', { email: this.email, password: this.password })
    },
    // onDismissed () {
    //   this.$store.dispatch('clearAuthError')
    // },
    switchInput () {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    // authError () {
    //   return this.$store.getters.authError
    // },
    loading () {
      return this.$store.getters.userloading
    }
  },
  watch: {
    user (value) {
      if (value !== null && value !== undefined) {
        // this.$store.dispatch('init')
        this.$router.replace('/app')
        // this.$router.back()
        // const routeData = this.$router.resolve({ name: 'routeName', query: { data: "someData" } })
        // window.open(routeData.href, '_blank')
        // const routeData = this.$router.resolve({ name: 'app' })
        // window.open('/app', '_blank')
      }
    }
  },
  data () {
    return {
      email: '',
      password: '',
      empid: '',
      inputType: 'password'
    }
  }
}
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
